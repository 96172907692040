import WatchList from "../components/WatchList";

import NotificationsBudget from "../components/NotificationsBudget";
import { useWatched } from "../contexts/Watched";
import { useUser } from "../contexts/User";

const Watch = () => {
  const { watched, setWatched } = useWatched();
  const { user } = useUser();

  return (
    <main className="max-w-[1600px] flex-1 pb-8">
      {/* <h1 className="mx-8 mt-8 text-lg font-medium leading-6 text-gray-900">
        Watch List
      </h1> */}
      <WatchList
        watched={watched}
        className={"mx-8"}
        onRemove={({ item }) => {
          if (window.confirm("Delete?") === true) {
            setWatched((currentWatched) => {
              return currentWatched.filter(
                (currentItem) => currentItem.id !== item.id
              );
            });
          }
        }}
      />
      {user && <NotificationsBudget className="mt-4" />}
    </main>
  );
};

export default Watch;
