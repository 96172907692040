// TODO: empty state Watch List

// login: https://explorer.space/login
// Usercontext
// use goby to reeload budget
// add funds to address
// display balance
// display budget (remaing notifications, total notifications) - https://tailwindui.com/components/application-ui/data-display/stats
// enter email, sms(soon)
// pages: settings wo man email eingibt, main page wo man watch list hat und input für hinzufügen
// Watch List: Item wachted (nftid, etc), active (on/off), remove
// paid XCH must be variable bound to usds value - tell users- must be current quoute/pirce.

import "react-toastify/dist/ReactToastify.css";
import SideBar from "./components/SideBar";
import Header from "./components/Header";
import { useState } from "react";

import { Route, Routes } from "react-router-dom";
import Watch from "./pages/Watch";
import Settings from "./pages/Settings";
import { WatchedProvider } from "./contexts/Watched";
import { UserProvider, useUser } from "./contexts/User";
import SignIn from "./components/SignIn";
import { GobyProvider } from "./contexts/Goby";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { user } = useUser();

  // const size = useWindowSize();
  // const isSmallScreen = size.width <= 640;

  return (
    <div className="flex flex-1 flex-col">
      <SideBar
        sidebarOpen={sidebarOpen}
        onClose={() => {
          setSidebarOpen(false);
        }}
      />
      <div className="flex flex-1 flex-col lg:pl-64">
        <Header
          onOpenSideBar={() => {
            setSidebarOpen(true);
          }}
        />
        {!user && (
          <div className="flex">
            <SignIn
              className={"mx-auto w-full max-w-[600px] px-10 pt-10 pb-10"}
            />
          </div>
        )}

        <Routes>
          <Route path="/" element={<Watch />} />
          {user && (
            <>
              <Route path="settings" element={<Settings />} />
            </>
          )}
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

const AppWithProviders = () => {
  return (
    <UserProvider>
      <GobyProvider>
        <WatchedProvider>
          <App />
        </WatchedProvider>
      </GobyProvider>
    </UserProvider>
  );
};

export default AppWithProviders;
