import { Menu, Transition } from "@headlessui/react";
import {
  Bars3CenterLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  EyeIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUser } from "../contexts/User";
import { useWatched } from "../contexts/Watched";
import { toXchAddress } from "../lib/chainAddress";
import classNames from "../lib/classNames";
import usePost from "../lib/usePost";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = ({ onOpenSideBar = () => {} }) => {
  const { watched, setWatched } = useWatched();
  const [searchValue, setSearchValue] = useState("");
  const { post: postWatched } = usePost("/api/watched");

  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef();

  const { user, logOut } = useUser();

  return (
    <div className="relative z-10 flex h-16 flex-shrink-0 shadow-lg lg:shadow">
      <button
        type="button"
        className="px-4 text-gray-400 lg:hidden"
        onClick={() => onOpenSideBar()}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="ml-3 flex flex-1">
        <div className="relative flex flex-1 items-center">
          <EyeIcon className="pointer-events-none absolute left-2.5 h-5 w-5 text-gray-500" />
          <input
            className="flex-1 rounded-md border-0 bg-gray-900 pl-9 text-white focus:ring-0"
            autoFocus
            type="text"
            ref={inputRef}
            value={searchValue}
            placeholder="Enter NFT, Collection, Address or Did"
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
          />
          <button
            type="button"
            className="ml-2 inline-flex items-center rounded-md border border-transparent bg-[#ac338eb8] px-4 py-2 pl-2.5 text-sm font-medium text-white shadow-sm drop-shadow hover:bg-[#ac338e] focus:outline-none"
            onClick={() => {
              if (!searchValue) {
                inputRef.current.focus();
                return;
              }

              const isAlreadyWatching = watched.some(
                (item) => item.id === searchValue
              );

              // TODO: check if id is valid
              if (isAlreadyWatching) {
                toast.error("Already watching this id");
                return;
              }

              if (location.pathname !== "/") {
                navigate("/");
              }

              setSearchValue("");

              setWatched((currentItems) => [
                {
                  id: searchValue,
                  name: "Click to set name",
                  watching: true,
                },
                ...currentItems,
              ]);

              postWatched({
                data: {
                  id: searchValue,
                },
              });
            }}
          >
            <PlusCircleIcon className="h-5 w-5" />
            <span className="ml-1.5">Watch</span>
          </button>
        </div>

        <div className="mr-3 flex items-center">
          {/* Profile dropdown */}
          <Menu as="div" className="relative ml-3">
            <div>
              <Menu.Button
                onClick={(event) => {
                  if (!user) {
                    event.preventDefault(); // dont open menu
                    // logIn();
                  }
                }}
                className="flex max-w-xs items-center rounded-md border border-slate-600 bg-slate-700 text-sm hover:bg-slate-600 focus:outline-none lg:p-2"
              >
                {user && (
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                )}
                <span className="ml-3 hidden text-sm font-medium lg:block">
                  {user ? (
                    user.authVia === "goby" ? (
                      // TODO: get xch address from pub key (user.name)
                      <span className="inline-block w-20 truncate">
                        {toXchAddress(user.name)}
                      </span>
                    ) : (
                      user.name
                    )
                  ) : (
                    "Sign in"
                  )}
                </span>
                {user ? (
                  <ChevronDownIcon
                    className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="ml-1 hidden h-4 w-4 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            {user && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          logOut();
                        }}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex w-full px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
