import { useUser } from "../contexts/User";
import classNames from "../lib/classNames";

const getIdType = (id) => {
  if (id.startsWith("nft1")) return "nft";

  if (id.startsWith("col1")) return "collection";

  if (id.startsWith("xch1")) return "address";

  if (id.startsWith("did:chia")) return "did";

  return "unknown";
};

const WatchList = ({
  className,
  watched = [],
  onRemove = () => {},
  // onSubmit = () => {},
  // onSearch = () => {},
  // searchValue = "",
}) => {
  const { user } = useUser();

  return (
    <div className={classNames("flex flex-col", className)}>
      {/* <div className="flex">
        <input
          className="flex-1 rounded border-gray-300"
          type="text"
          value={searchValue}
          placeholder="Enter NFT, Collection, Address or Did"
          onChange={(event) => {
            onSearch({ value: event.target.value });
          }}
        />
        <button
          type="button"
          className="ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 pl-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            onSubmit({ searchValue });
          }}
        >
          <PlusCircleIcon className="h-5 w-5" />
          <span className="ml-1.5">Watch</span>
        </button>
      </div> */}
      <div className="mt-4">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow-md ring-1 ring-black  md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                {/* <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-2.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6"
                    >
                      Name
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-2.5 text-left text-sm font-semibold text-gray-500"
                    >
                      Id
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2.5 text-left text-sm font-semibold text-gray-500"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2.5 text-left text-sm font-semibold text-gray-500"
                    >
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead> */}
                <tbody className="divide-y divide-gray-800 bg-slate-700">
                  {watched.map((item) => (
                    <tr key={item.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                        <div className="-m-2 p-2">{item.name}</div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        {item.id}
                      </td>
                      <td className="w-1 whitespace-nowrap px-3 py-1 text-xs text-slate-300">
                        {getIdType(item.id)}
                      </td>
                      <td className="w-1 whitespace-nowrap px-3 py-1 text-right text-sm">
                        {item.watching ? (
                          <div className="-my-0.5 inline-flex items-center rounded-full px-2.5 py-1 text-xs text-teal-500">
                            watching
                          </div>
                        ) : (
                          "inavtive"
                        )}
                      </td>
                      <td className="relative w-1 whitespace-nowrap px-3 py-1 text-right text-sm font-medium sm:pr-6">
                        <div className="flex justify-end space-x-2">
                          {/* <button
                            className="text-indigo-600 hover:text-indigo-900"
                            // onClick={() => onRemove({ item })}
                          >
                            Pause
                          </button> */}
                          <button
                            className="text-xs text-sky-300 hover:text-sky-200"
                            onClick={() => onRemove({ item })}
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchList;
