import { useCallback, useEffect, useRef, useState } from "react";

const useGet = (url, { params, auto = true } = {}) => {
  const [response, setResponse] = useState();
  const [data, setData] = useState();
  const initialParams = useRef(params);
  const abortController = useRef();

  const abort = () => abortController.current?.abort();

  const get = useCallback(
    async ({ params = initialParams.current, autoAbort = true } = {}) => {
      try {
        // abort any currently running requests
        if (autoAbort) {
          abort();
        }
        abortController.current = new AbortController();
        const signal = abortController.current.signal;

        const fetchUrl = new URL(url, window.location);
        for (const name in params) {
          fetchUrl.searchParams.set(name, params[name]);
        }

        const response = await fetch(fetchUrl, {
          signal,
        });
        setResponse(response);
        const data = await response.json();
        setData(data);
        return { data, response };
      } catch (error) {
        if (error.name !== "AbortError") {
          throw error;
        }
      }
    },
    [url]
  );

  useEffect(() => {
    if (auto === true) {
      get();
    }

    return () => {
      abort();
    };
  }, [get, auto]);

  return { get, response, data, setData, abort };
};

export default useGet;
