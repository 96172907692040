import { createContext, useContext, useEffect } from "react";
import useLocalStorageState from "use-local-storage-state";
import useGet from "../lib/useGet";
import usePost from "../lib/usePost";

const UserContext = createContext();

const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useLocalStorageState("user", {
    defaultValue: null,
  });

  // TODO
  const { data: userData } = useGet("/api/user");
  const { post: postLogout } = usePost("/api/logout");

  useEffect(() => {
    if (userData) {
      // setUser(userData.user);
      setUser();
      console.log("userData", userData);
    }
  }, [setUser, userData]);

  const logOut = () => {
    postLogout();
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ logOut, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
export { UserProvider, useUser };
