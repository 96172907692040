import { Dialog, Transition } from "@headlessui/react";
import {
  BellAlertIcon,
  CogIcon,
  ListBulletIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import classNames from "../lib/classNames";
import logo from "../assets/logo.png";

const navigation = [
  { name: "Watch List", href: "/", icon: ListBulletIcon, current: true },
  {
    name: "Notifications",
    href: "/notifications",
    icon: BellAlertIcon,
    current: false,
  },
  { name: "Settings", href: "/settings", icon: CogIcon },
  { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
];

const secondaryNavigation = [
  // { name: "Settings", href: "/settings", icon: CogIcon },
  // { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
  // { name: "Privacy", href: "/privacy", icon: ShieldCheckIcon },
];

const SideBar = ({ sidebarOpen, onClose = () => {} }) => {
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-100 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-100 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-100"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                      onClick={() => onClose()}
                    >
                      <XCircleIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <Content onClose={onClose} />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden border-r border-gray-700 border-opacity-30 lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <Content onClose={onClose} />
      </div>
    </>
  );
};

const Content = ({ onClose }) => {
  return (
    <div className="flex flex-grow flex-col overflow-y-auto bg-gray-900 pt-5 pb-4">
      <Link className="flex items-center px-4 text-lg text-white" to="/">
        <img src={logo} alt="Logo" className="h-10 w-10" />
        <span className="ml-3">XCHWatch</span>
      </Link>
      <nav
        className="mt-5 flex flex-1 flex-col divide-y divide-gray-700 overflow-y-auto"
        aria-label="Sidebar"
      >
        <div className="space-y-1 px-2">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              onClick={onClose}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-slate-700 text-white"
                    : " hover:bg-slate-700 hover:text-white",
                  "flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6"
                )
              }
            >
              <item.icon
                className="mr-4 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          ))}
        </div>
        {/* <div className="mt-6 pt-6">
          <div className="space-y-1 px-2">
            {secondaryNavigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                onClick={onClose}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-cyan-600 text-white"
                      : "text-cyan-100 hover:bg-cyan-700 hover:text-white",
                    "flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6"
                  )
                }
              >
                <item.icon
                  className="mr-4 h-6 w-6 text-cyan-200"
                  aria-hidden="true"
                />
                {item.name}
              </NavLink>
            ))}
          </div>
        </div> */}
      </nav>
    </div>
  );
};

export default SideBar;
