// https://gist.github.com/dimitrysuen/620c6c07173c0f28948a44e26bbf0d53
// https://gist.github.com/dimitrysuen/7dac28da11f22f4e2a8544dac8348aa7

import React, { createContext, useContext, useState, useEffect } from "react";
// import toast from "react-hot-toast";
// import { useTranslation } from "react-i18next";
// import GobyModal from "../components/GobyModal"; // TODO
// import { switchNetwork } from "../contexts/NetworkContext";
import { toast } from "react-toastify";

function stringToHex(string) {
  const bytes = new TextEncoder().encode(string);
  return (
    "0x" +
    Array.from(bytes, (byte) => byte.toString(16).padStart(2, "0")).join("")
  );
}

// TODO
const t = (text) => text;

const GobyContext = createContext({
  account: "",
  changeAccount: () => {},
});

const useGoby = () => useContext(GobyContext);

const isGobyInstalled = () => {
  const { chia } = window;
  return Boolean(chia && chia.isGoby);
};

function GobyProvider({ children }) {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [account, setAccount] = useState();

  const [isCreatingTransfer, setIsCreatingTransfer] = useState(false);

  // const { t } = useTranslation();

  useEffect(() => {
    if (isGobyInstalled()) {
      window.chia.on("accountsChanged", (accounts) => {
        setAccount(accounts?.[0]);
      });

      //   window.chia.on("chainChanged", async () => {
      //     const chainId = await window.chia.request({ method: "chainId" });

      //     if (chainId === "0x01") {
      //       switchNetwork("mainnet");
      //     } else if (chainId === "0x02") {
      //       switchNetwork("testnet");
      //     }
      //   });

      window.chia
        .request({ method: "accounts" })
        .then((accounts) => {
          setAccount(accounts?.[0]);
        })
        .catch((error) => {
          // this here will fire on each page load when goby is installed but not yet configured

          // ignore goby error to not show error on each page load (could show notice somewhere on page later)
          if (error.code) {
            return;
          }

          // throw to still now aboout any non handled errors (should show up in sentry)
          throw error;
        });
    }
  }, []);

  const handleConnect = async () => {
    if (!isGobyInstalled()) {
      // setIsModalOpen(true);
      toast.info("Please install Goby Browser Wallet Extension.");
      return;
    }

    try {
      const accounts = await window.chia.request({
        method: "requestAccounts",
      });

      setAccount(accounts?.[0]);

      return account;
    } catch (error) {
      // this will fire when user tries to click connect or interact with not yet configured wallet

      if (error.code === 4001) {
        toast.error(t("toast.gobyError4001"));
        return;
      }

      if (error.code === 4900) {
        toast.error(t("toast.gobyError4900"));
        return;
      }

      if (error.code && error.message) {
        toast.error(error.message);
        return;
      }

      // throw to still now aboout any non handled errors (should show up in sentry)
      throw error;
    }
  };

  const handleDisconnect = async () => {
    // wait for goby wallet to implement disconnect
    //await window.chia.request({ method: 'deactivate' })
  };

  // interface AssetAmount {
  //   assetId: string;
  //   amount: Amount,
  // }

  const createOffer = async ({ offerAssets, requestAssets }) => {
    console.log({ offerAssets, requestAssets });

    if (!isGobyInstalled()) {
      toast.info("Please install Goby Browser Wallet Extension.");
      // setIsModalOpen(true);
      return;
    }

    try {
      const result = await window.chia.request({
        method: "createOffer",
        params: {
          offerAssets,
          requestAssets,
        },
      });

      return result;
    } catch (error) {
      // if is goby error
      // if (error.code) {
      toast.error(error.message);
      // }

      throw error;
    }
  };

  const acceptOffer = async (offer) => {
    if (!isGobyInstalled()) {
      toast.info("Please install Goby Browser Wallet Extension.");
      // setIsModalOpen(true);
      throw new Error("gobyNotInstalled");
    }

    try {
      console.log("goby trying to take offer", offer);

      const result = await window.chia.request({
        method: "takeOffer",
        params: {
          offer: offer,
          fee: 0,
        },
      });

      toast.success(t("toast.Offer accepted"));

      return result;
    } catch (error) {
      // if is goby error
      // if (error.code) {
      toast.error(error.message);
      // }

      throw error;
    }
  };

  const transfer = async (transferParams) => {
    // interface TransferParams {
    //   to: string;
    //   amount: Amount;
    //   assetId: string;
    //   memos?: HexString[];
    // }

    console.log({ transferParams });

    if (!isGobyInstalled()) {
      toast.info("Please install Goby Browser Wallet Extension.");
      // setIsModalOpen(true);
      return;
    }

    setIsCreatingTransfer(true);
    try {
      const result = await window.chia.request({
        method: "transfer",
        params: transferParams,
      });

      if (result) {
        toast.success("Transaction successfully created");
        setIsCreatingTransfer(false);
      }

      return result;
    } catch (error) {
      // if is goby error
      // if (error.code) {
      setIsCreatingTransfer(false);
      toast.error(error.message);
      // }
      throw error;
    }
  };

  const sign = async ({ message = "" }) => {
    try {
      const publicKeys = await window.chia.request({ method: "getPublicKeys" });

      const publicKey = publicKeys[0];

      const hexMessage = stringToHex(message);
      const params = {
        publicKey,
        message: hexMessage,
      };
      const signature = await window.chia.request({
        method: "signMessage",
        params,
      });

      return { signature, publicKey, message };
    } catch (error) {
      toast.error(error.message);
      return { error };
    }
  };

  return (
    <GobyContext.Provider
      value={{
        account: account,
        isGobyInstalled: isGobyInstalled(),
        handleConnect,
        handleDisconnect,
        acceptOffer,
        createOffer,
        transfer,
        isCreatingTransfer,
        sign,
      }}
    >
      {children}
      {/* <GobyModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} /> */}
    </GobyContext.Provider>
  );
}

export default GobyContext;
export { useGoby, GobyProvider, isGobyInstalled };
