import { bech32m } from "bech32";
import { Buffer } from "buffer";
// import { isTestnet } from "../contexts/NetworkContext";

const toChainAddress = (address, prefix) => {
  if (!address || !prefix) return null;

  if (address.startsWith("0x")) address = address.slice(2);

  if (!Buffer.isBuffer(address)) {
    address = Buffer.from(address, "hex");
  }
  return bech32m.encode(prefix, bech32m.toWords(address));
};

const toXchAddress = (address) => toChainAddress(address, "xch");

const toNftAddress = (address) => toChainAddress(address, "nft");

const toCollectionAddress = (address) => toChainAddress(address, "col");

const toDidAddress = (address) => toChainAddress(address, "did:chia:");

export {
  toChainAddress,
  toXchAddress,
  toNftAddress,
  toCollectionAddress,
  toDidAddress,
};
