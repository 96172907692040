import { ChevronDownIcon, ScaleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useGoby } from "../contexts/Goby";
import classNames from "../lib/classNames";

// TODO: spinner... awaiting transaction income

const NotificationsBudget = ({ className }) => {
  const [isIncreaseShown, setIsIncreaseShown] = useState(false);

  const goby = useGoby();

  const [gobyAmount, setGobyAmount] = useState("");

  return (
    <div
      className={classNames(
        "mx-8 overflow-hidden rounded-lg bg-white shadow",
        className
      )}
    >
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ScaleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-4 w-0 flex-1">
            <dl>
              <dt className="truncate text-sm font-medium text-gray-500">
                Notification Budget
              </dt>
              <dd>
                <div className="text-lg font-medium text-gray-900">
                  234 remaining of 2000{" "}
                  <Link to="/notifications">(1766 sent)</Link>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-5 py-3">
        <button
          onClick={() => {
            setIsIncreaseShown(!isIncreaseShown);
          }}
          className="flex items-center text-cyan-700 hover:text-cyan-900"
        >
          <span className="text-sm font-medium">Increase Budget</span>
          <ChevronDownIcon className="ml-1 h-4 w-4" />
        </button>
        {isIncreaseShown && (
          <div className="mt-1 text-sm">
            <div>
              <span>Send XCH to the follwing address: </span>
              <code className="break-all text-xs">
                xch1f7juvz03jyvlx9r8npm9ap7w0yr9x9zyfegfw9qy27hgtswuxaas8hck9p
              </code>
            </div>
            <div className="flex items-center">
              <div className="relative w-28 rounded-md shadow-sm">
                {/* <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div> */}
                <input
                  type={"number"}
                  value={gobyAmount}
                  onChange={(event) => {
                    setGobyAmount(event.target.value);
                  }}
                  className="block w-full rounded-md border-gray-300 pr-12 text-sm"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span className="text-sm text-gray-500" id="price-currency">
                    XCH
                  </span>
                </div>
              </div>

              <button
                type="button"
                className="ml-2 inline-flex h-10 items-center rounded-md border border-transparent bg-indigo-600 px-3 text-sm text-white shadow-sm hover:bg-indigo-700"
                onClick={async () => {
                  try {
                    const result = await goby.transfer({
                      to: "xch1280hr7pxaq6v59jh2aycu07jf4pt836x6zpx4tu353xhn0ru6gxq7glysn",
                      amount: gobyAmount * 1000000000000,
                      assetId: "", // "" = xch
                    });
                    console.log("result", result);
                    // result is undefined if goby wallet is not installed
                    if (result) {
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                {goby.isCreatingTransfer ? "Please wait..." : "Send with Goby"}
              </button>
            </div>
            <div className="mt-1">Cost per 100 notifications: 0.2 xch</div>
            <div className="mt-1 flex text-xs text-gray-500">
              Budget will increase instantly after transaction completion.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsBudget;
