import { createContext, useContext, useState } from "react";
// import useGet from "../lib/useGet";

const WatchedContext = createContext();

const useWatched = () => useContext(WatchedContext);

const WatchedProvider = ({ children }) => {
  const [watched, setWatched] = useState([
    {
      id: "col1z0ef7w5n4vq9qkue67y8jnwumd9799sm50t8fyle73c70ly4z0ws0p2rhl",
      name: "Chia Friends",
      watching: true,
    },
    {
      id: "nft1cefpk2qe8ygtvhrtjaw645u8tkudv7q609wy0qel985eucmxx93qya03jd",
      name: "WadePanda #1614",
      watching: true,
    },
    {
      id: "xch1f7juvz03jyvlx9r8npm9ap7w0yr9x9zyfegfw9qy27hgtswuxaas8hck9p",
      name: "My Wallet",
      watching: true,
    },
    {
      id: "did:chia:1epch3ga2hh2u0dxuvx7j070mcnz5c0su0sewcs86ulur8az0r49sqakfvm",
      name: "Rohan",
      watching: true,
    },
  ]);

  // TODO
  // const { post: getWatched, data: watchedData } = useGet("/watched");

  return (
    <WatchedContext.Provider value={{ watched, setWatched }}>
      {children}
    </WatchedContext.Provider>
  );
};

export default WatchedContext;
export { WatchedProvider, useWatched };
