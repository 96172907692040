import { Switch } from "@headlessui/react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../contexts/User";
import classNames from "../lib/classNames";
import usePost from "../lib/usePost";

const Settings = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);

  // TODO: get settings from user context

  const [email, setEmail] = useState("");

  const { user } = useUser();

  const { post: postSettings } = usePost("/api/settings");

  const saveSettings = (data) => {
    postSettings({
      data: {
        notificationsEnabled,
        email,
        ...data,
      },
    });
  };

  return (
    <main className="flex-1 bg-gray-50 pb-8">
      <h1 className="mx-8 mt-8 text-lg font-medium leading-6 text-gray-900">
        Settings
      </h1>

      <div className="mx-8 mt-8 bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="flex w-full flex-col md:w-[400px]">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={user?.email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 text-sm shadow-sm"
          />
          <span className="mt-1.5 text-xs text-gray-500">
            Receives notifications
          </span>
        </div>
        <div className="mt-8 w-full flex-col md:w-[400px]">
          <Switch.Group as="div" className="flex items-center">
            <Switch.Label
              as="dt"
              className="block text-sm font-medium text-gray-700"
              passive
            >
              Notifications enabled
            </Switch.Label>
            <dd className="ml-auto flex">
              <Switch
                checked={notificationsEnabled}
                onChange={(enabled) => {
                  setNotificationsEnabled(enabled);
                  saveSettings({ notificationsEnabled: enabled });
                }}
                className={classNames(
                  notificationsEnabled ? "bg-purple-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-auto"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    notificationsEnabled ? "translate-x-5" : "translate-x-0",
                    "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </dd>
          </Switch.Group>
        </div>
        <button
          type="button"
          className="mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
          onClick={() => {
            saveSettings();
            toast.success("Settings saved");
          }}
        >
          Save
        </button>
      </div>
    </main>
  );
};

export default Settings;
