import { useCallback, useEffect, useRef, useState } from "react";

const usePost = (url, { data = {}, auto = false } = {}) => {
  const [response, setResponse] = useState();
  const [responseData, setResponseData] = useState();
  const initialData = useRef(data);
  const abortController = useRef();

  const abort = () => abortController.current?.abort();

  const post = useCallback(
    async ({ data = initialData.current, autoAbort = true } = {}) => {
      try {
        // abort any currently running requests
        if (autoAbort) {
          abort();
        }
        abortController.current = new AbortController();
        const signal = abortController.current.signal;

        const fetchUrl = new URL(url, window.location);

        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          signal,
          body: JSON.stringify(data),
        });
        setResponse(response);

        let responseData;

        try {
          responseData = await response.clone().json();
        } catch (error) {
          responseData = await response.text();
        }

        // const responseData = await response.json();
        setResponseData(responseData);
        return { data: responseData, response };
      } catch (error) {
        if (error.name !== "AbortError") {
          throw error;
        }
      }
    },
    [url]
  );

  useEffect(() => {
    if (auto === true) {
      post();
    }

    return () => {
      abort();
    };
  }, [post, auto]);

  return {
    post,
    response,
    data: responseData,
    setData: setResponseData,
    abort,
  };
};

export default usePost;
